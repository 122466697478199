import loadable from "@loadable/component";
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Seo from "../components/SEO";

const ContactSectionComponent = loadable(() =>
  import("../components/ContactSection")
);

const Contact = () => {
  return (
    <Layout>
      <Wrapper>
        {typeof window !== "undefined" && <ContactSectionComponent />}
        <div className="note">
          <p>
            Informujemy, że przyjmujemy pacjentów po uprzednim kontakcie telefonicznym.
          </p>
          <p><b>Nie odbieramy telefonu? Na pewno oddzwonimy!</b></p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const Head = () => {
  return <Seo title="Kontakt - Animal" />;
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 120rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .note {
    padding: 2rem;
    text-align: center;
    margin-top: -3rem;
    margin-bottom: 2rem;
  }
`;

export default Contact;
